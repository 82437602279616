export type defaultThemeType = {
  colors: {
    white: string;
    product: {
      primary: string;
      primaryHover: string;
      primaryActive: string;
      highlighting: string;
      primaryBorder: string;
      resultCodeBlock: string;
      resultCodeSlide: string;
    };
    alert: {
      notification: string;
      status: string;
      border: string;
      warning: string;
      success: string;
      glassButton: string;
      checkedRadioButton: string,
    };
    neutral: {
      disable: string;
    };
    black: {
      100: string;
      200: string;
    },
    gray: {
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
    },
    blue: {
       50: string;
      100: string;
      200: string;
      300: string;
    },
    violet: {
      50: string;
      100: string;
      300: string;
      500: string;
    },
    orange: {
      25: string;
      50: string;
      100: string;
      200: string;
    },
    green:{
      50:  string,
      100: string,
    },
    red: {
      100: string;
      400: string;
      500: string;
    },
    pink: {
      100: string;
      200: string;
    },
    steps: {
      active: {
        before: string;
        main:   string;
        after:  string;
      },
      completed: {
        before: string;
        main:   string;
        after:  string;
      },
      default:{
        before: string;
        main:   string;
        after:  string;
      }
    }
  };
};

const defaultTheme: defaultThemeType = {
  colors: {
    white: '#FFFFFF',
    product: {
      primary: 'linear-gradient(161.11deg, #7977E9 22.76%, #9881E6 86.77%, #A281E4 119.46%, #B282E0 141.51%, #C082DD 176.66%, #C982DB 203.9%, #E182D6 241.18%, #EB82D4 265.19%);',
      primaryHover: '#9578E9',
      primaryActive: '#3E6AFF',
      highlighting: '#ffffff',
      primaryBorder: '#B7B5F1',
      resultCodeBlock: '#DCDBFF',
      resultCodeSlide: '#FFD4D8'
    },
    alert: {
      notification: '#F36ABC',
      status: '#86EAC0',
      border: '#B7B5F1',
      warning: '#EC1325',
      success: '#68DEB3',
      glassButton: '#84AFE1',
      checkedRadioButton: '#52E0A4',
    },
    neutral: {
      disable: '#C8CBD7',
    },
    black: {
      100: '#2D2D2D',
      200: '#1A1A22',
    },
    gray: {
      100: '#F7F7F8',
      200: '#CECECE',
      300: '#DDDEDF',
      400: '#96999C',
      500: '#777777',
      600: '#666666',
    },
    blue: {
       50: '#F3FBFF',
      100: '#68B3DE',
      200: '#1C9BE3',
      300: '#5AB7EB',
    },
    violet: {
      50: '#F8F8FF',
      100: '#DCDBFF',
      300: '#B7B5F1',
      500: '#7A78E9',
    },
    orange: {
      25: '#ffd4d880',
      50: '#FFD4D8',
      100: '#FFD60A',
      200: '#EC9000',
    },
    green:{
      50: '#E0FEF3',
      100:'#52E0A4',
    },
    red: {
      100: '#FFD4D8',
      400: '#DE6868',
      500: '#EC1325',
    },
    pink: {
      100: '#FFCFEC',
      200: '#F36ABC',
    },
    steps: {
      active: {
        before: '#52E0A4',
        main:   '#FFD60A',
        after:  '#CECECE',
      },
      completed: {
        before: '#52E0A4',
        main:   '#52E0A4',
        after:  '#52E0A4',
      },
      default:{
        before: '#CECECE',
        main:   '#CECECE',
        after:  '#CECECE',
      }
    },
  },
};

export default defaultTheme;
