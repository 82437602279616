import { AxiosInstance } from "axios";

export type CreateIhcReferralApiRequestData = {
  laboratory: number;
  patient: {
    ulid: string;
    country: number;
    address: string;
    gender: string;
    last_name: string;
    first_name: string;
    middle_name: string;
    oms_number: string;
    snils_number: string;
    date_of_birth: string;
  };
  ambulatory_card: {
    organization: number;
    custom_organization: string;
    number: string;
  };
  doctor: {
    ulid: string;
    organization: number;
    last_name: string;
    first_name: string;
    middle_name: string;
  };
  referral_date: string;
  localization: number;
  icd_10: number;
  clinical_diagnosis: string;
  t_category: string;
  t_sub: string;
  n_category: string;
  n_sub: string;
  m_category: string;
  m_sub: string;
  tnm_non_applicable: boolean;
  tnm_unknown: boolean;
  stage: string;
  stage_non_applicable: boolean;
  stage_unknown: boolean;
  additional_info: string;
  additional_info_non_applicable: boolean;
  additional_info_unknown: boolean;
  previous_analysis_results: string;
  previous_analysis_results_non_applicable: boolean;
  previous_analysis_results_unknown: boolean;
  pre_ambulatory_care: string;
  pre_ambulatory_care_non_applicable: boolean;
  pre_ambulatory_care_unknown: boolean;

  bio: {
    material_type: string;
    method_of_acquisition: string;
    sample_type: string;
    date_of_acquisition: string;
    // blocks_and_slides: {
    //   organization: 4;
    //   blocks: [
    //     { organ: "Печень"; cypher: "12345678-09" },
    //     { organ: "Лёгкое"; cypher: "12345678-09" }
    //   ];
    //   slides: [
    //     { organ: "Печень"; cypher: "12345678-09" },
    //     { organ: "Лёгкое"; cypher: "12345678-09" }
    //   ];
    // };
  };
  markers: Array<number>;
};

export type CreateIhcReferralApiResponseData = {
  success: boolean;
  referral: string;
};

export class ReferralsService {
  private httpClient: AxiosInstance | null = null;

  constructor(httpClient: any) {
    this.httpClient = httpClient;
  }

  async createIhcReferral(
    props: CreateIhcReferralApiRequestData
  ): Promise<CreateIhcReferralApiResponseData | undefined> {
    const url = `registry/referral/ihc/create/`;

    return await (
      await this.httpClient?.post<CreateIhcReferralApiResponseData>(
        url,
        JSON.stringify(props)
      )
    )?.data;
  }
}
