import { AxiosInstance } from "axios";

export type ApiDiagnosis = {
  //   id: 1013;
  //   code: "";
  //   name: "ЗЛОКАЧЕСТВЕННЫЕ НОВООБРАЗОВАНИЯ ОРГАНОВ ПИЩЕВАРЕНИЯ (C15-C26)";
  //   level: 0;
  //   localization: [1, 2, 5, 6, 8];
  //   nested_list: [];

  id: number;
  code: string;
  name: string;
  level: number;
  localization: Array<number>;
  nested_list: Array<ApiDiagnosis>;
};

export class DiagnosesService {
  private httpClient: AxiosInstance | null = null;

  constructor(httpClient: any) {
    this.httpClient = httpClient;
  }

  async searchDiagnoses(props: {
    localization_id: number;
  }): Promise<Array<ApiDiagnosis> | undefined> {
    const urlSearchParams = new URLSearchParams("");

    if (props.localization_id) {
      urlSearchParams.append("localization_id", String(props.localization_id));
    }

    const url = `registry/icd/search?${urlSearchParams}`;

    return await (
      await this.httpClient?.get<Array<ApiDiagnosis>>(url)
    )?.data;
  }
}
