import styled, { css } from 'styled-components';
import Button from '../../shared/Button';
import { Link } from 'react-router-dom';
import FormTitle from '../../shared/FormTitle';
import Input from '../../shared/Input';
import FormErrorText from '../../shared/FormErrorText';

export const TFormTitle = styled(FormTitle)`
  text-align: left;
  margin: 0 52px 30px;
  font-size: 24px;
`;

export const TFieldsContainer = styled.div`
  padding: 0 52px;
`;

const TCreateAccountStyle = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;

export const TCreateAccountText = styled.p`
  ${TCreateAccountStyle};
  margin: 46px 0 0;
  
  color: ${({ theme }) => theme.colors.gray[500]};
`;

export const TCreateAccountLink = styled(Link)`
  ${TCreateAccountStyle};
  color: ${({ theme }) => theme.colors.product.primaryActive};
  text-decoration: none;
  display: block;
  margin: 0 auto;

  &:hover {
    color: ${({ theme }) => theme.colors.product.primaryHover};
  }
`;

export const TForgotPasswordLink = styled(Link)`
  text-align: center;
  color: ${({ theme }) => theme.colors.gray[500]};
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  cursor: pointer;
  display: block;
  margin-top: 70px;
  
  &:hover {
    color: ${({ theme }) => theme.colors.product.primaryHover};
  }
`;

export const TButton = styled(Button)`
  margin-bottom: 38px;
  margin-top: 60px;
  width: 100%;
  height: 44px;
`;

export const TInput = styled(Input)`
  margin-bottom: 20px;
`;

export const TFormErrorText = styled(FormErrorText)`
  margin: 10px 0 35px;
  padding-left: 20px;
`;

export const TModalText = styled.div`
  margin: 46px 0 26px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px; 
`
