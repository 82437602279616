import styled from "styled-components";
import { Title } from "../../../../../../componentsNew/Title";
import { Input } from "../../../../../../componentsNew/Input";
import { Button } from "../../../../../../componentsNew/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  editedPatientSelector,
  resetSearch,
  rfSearchBirthdateSelector,
  rfSearchFirstNameSelector,
  rfSearchLastNameSelector,
  rfSearchPatronymicSelector,
  setRfSearchBirthdate,
  setRfSearchFirstName,
  setRfSearchLastName,
  setRfSearchPatronymic,
  setSearchProcessInitiated,
} from "../../../../redux/patient";
import { usePatients } from "../../../../hooks/usePatients";
import { Datepicker } from "../../../../../../componentsNew/Datepicker";

export const RfSearchPersonalData = () => {
  const dispatch = useDispatch();

  const rfSearchFirstName = useSelector(rfSearchFirstNameSelector);
  const rfSearchLastName = useSelector(rfSearchLastNameSelector);
  const rfSearchPatronymic = useSelector(rfSearchPatronymicSelector);
  const rfSearchBirthdate = useSelector(rfSearchBirthdateSelector);

  const editedPatient = useSelector(editedPatientSelector);

  const isFilledPatientSearchDetails = Boolean(
      rfSearchBirthdate &&
      rfSearchFirstName &&
      rfSearchLastName
  );

  const onClear = () => {
    dispatch(resetSearch());
  };

  const { searchPatients } = usePatients();

  const onSearch = async () => {
    dispatch(setSearchProcessInitiated(true));

    await searchPatients({
      lastName: rfSearchLastName || "",
      firstName: rfSearchFirstName || "",
      middleName: rfSearchPatronymic || "",
      dateOfBirth: rfSearchBirthdate || "",
      // countryAlpha: "ru",
    });

    // dispatch(setSearchProcessResult(null));
  };

  return (
    <StyledPersonalDataContainer>
      <StyledRowContainer>
        <Title level="3">Введите персональные данные</Title>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Имя"
            required={true}
            placeholder="Иван"
            value={rfSearchFirstName || ""}
            onChange={(event) => {
              dispatch(setRfSearchFirstName(event.target.value));
            }}
            disabled={editedPatient?.confirmed}
          />
        </StyledInputContainer>

        <StyledInputContainer>
          <Input
            title="Фамилия"
            required={true}
            placeholder="Петров"
            value={rfSearchLastName || ""}
            onChange={(event) => {
              dispatch(setRfSearchLastName(event.target.value));
            }}
            disabled={editedPatient?.confirmed}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Отчество"
            placeholder="Иванович"
            value={rfSearchPatronymic || ""}
            onChange={(event) => {
              dispatch(setRfSearchPatronymic(event.target.value));
            }}
            disabled={editedPatient?.confirmed}
          />
        </StyledInputContainer>

        <StyledInputContainer>
          <Datepicker
            required={true}
            title="Дата рождения"
            placeholder="Иванович"
            value={rfSearchBirthdate || ""}
            onChange={(event) => {
              dispatch(setRfSearchBirthdate(event.target.value));
            }}
            disabled={editedPatient?.confirmed}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledPersonalDataButtonsContainer>
        <Button
          size="L"
          type="SECONDARY"
          text="Очистить"
          onClick={() => {
            onClear();
          }}
          disabled={editedPatient?.confirmed}
        />

        <Button
          size="L"
          type="PRIMARY"
          text="Найти пациента"
          onClick={() => {
            onSearch();
          }}
          disabled={editedPatient?.confirmed || !isFilledPatientSearchDetails}
        />
      </StyledPersonalDataButtonsContainer>
    </StyledPersonalDataContainer>
  );
};

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 16px;
`;

const StyledPersonalDataContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
`;

const StyledPersonalDataButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`;

const StyledInputContainer = styled.div`
  min-width: 340px;
`;

// const StyledInputContainer2 = styled.div`
//   width: 100%;
// `;
