import { AxiosInstance } from "axios";

export type ApiMarker = {
  //   id: 3;
  //   marker: "BRAF";
  //   Therapy: [
  //     "Vemurafenib/Cobimetinib",
  //     "Encorafenib/Binimetinib",
  //     "Dabrafenib/Trametinib",
  //     "Trametinib"
  //   ];

  id: number;
  marker: string;
  Therapy: Array<string>;
};

export class MarkersService {
  private httpClient: AxiosInstance | null = null;

  constructor(httpClient: any) {
    this.httpClient = httpClient;
  }

  async searchMarkers(props: {
    localization: number;
  }): Promise<Array<ApiMarker> | undefined> {
    const urlSearchParams = new URLSearchParams("");

    if (props.localization) {
      urlSearchParams.append("localization", String(props.localization));
    }

    const url = `registry/referral/ihc/markers?${urlSearchParams}`;

    return await (
      await this.httpClient?.get<Array<ApiMarker>>(url)
    )?.data;
  }
}
