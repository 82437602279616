import { useSelector } from "react-redux";
// import { useHttpClient } from "../../../hooks/useHttpClient";
// import { LocalizationsService } from "../../../services/Localizations";
import { localizationsSelector } from "../redux/clinicalData";

export const useLocalizations = () => {
  // const dispatch = useDispatch();

  // const httpClient = useHttpClient();

  const localizations = useSelector(localizationsSelector);

  // const searchLocalizations = async () => {
  //   const localizationService = new LocalizationsService(httpClient);

  //   const _localizations = await localizationService.searchLocalizations();

  //   if (_localizations?.length) {
  //     dispatch(setLocalizations(_localizations));
  //   }
  // };

  // return { localizations, searchLocalizations };
  return { localizations };
};
