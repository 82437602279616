import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '../../shared/Button';
import FormTitle from "../../shared/FormTitle";

export const TFieldsContainer = styled.div`
 
  & > *:last-child {
    margin-bottom: 24px;
  }

  padding: 0 52px;
`;

export const TNameInputContainer = styled.div`
  display: flex;
  margin-bottom: 10px;

  & > * {
    margin-right: 12px;
    width: 50%;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

export const TTermsContainer = styled.div`
  margin: 17px auto 23px;
  width: 372px;
  display: flex;
  align-items: flex-start;
`;

export const TTermsCopy = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  margin-left: 12px;
`;

export const TLink = styled(Link)`
  color: ${({ theme }) => theme.colors.product.primaryActive};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.product.primaryHover};
  }
`;

export const TBottomCopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 14px;
`;

const TTextStyle = css`
  color: ${({ theme }) => theme.colors.gray[500]};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const TText = styled.p`
  ${TTextStyle};
  text-align: center;
`;

export const TLoginLink = styled(Link)`
  ${TTextStyle};

  color: ${({ theme }) => theme.colors.product.primaryActive};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.product.primaryHover};
  }
`;

export const TSuccessTextWr = styled.div`
  padding: 0 52px;
`;

export const TSuccessText = styled.p`
  font-style: normal;
  color: #000000;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
`;

export const TButton = styled(Button)`
  margin: 78px auto 0;
`;

export const TPasswordContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
`;

export const TPasswordRequirementsHint = styled.div`
  position: absolute;
  bottom: 90px;
  padding: 18px 20px 18px 30px;
  border: 1px solid #DDDEDF;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.gray[500]};

  & > ul {
    list-style: none;
    padding: 0;
    margin: 10px 0 0;
  }
`;

export const TTitle = styled(FormTitle)`
  padding: 0 52px;
  display: flex;
  font-size: 24px;
  margin-top: 24px;
  font-weight: 600;
  line-height: 50px;
  margin-bottom: 10px;
`;

export const TContainerEmail = styled.div`
  width: 100%;
  margin-bottom: 10px;
`
