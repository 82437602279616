export const Colors = {
  RED: {
    500: "#FE3546",
  },
  ORANGE: {
    400: "#FFD60A",
  },

  GREEN: {
    500: "#0ed962",
  },

  MONOCHROM: {
    100: "#EDEEF1",
    200: "#D8DBDF",
    400: "#8D94A3",
    950: "#25272C",
    800: "#3F434D",
  },

  BRAND: {
    50: "#EEF3FF",
    500: "#3E6AFF",
  },
};
