import { StepState } from "../../../componentsNew/Steps/types/Step";
import { PatientState } from "../redux/patient";
import { ReferrerState } from "../redux/referrer";

export class StepStateChecker {
  static getResearchTypeStepState({
    currentStep,
    researchType,
  }: {
    currentStep: { code: string | null; name: string | null };
    researchType: { code: string | null; name: string | null };
  }) {
    if (currentStep.code === "RESEARCH_TYPE") {
      return StepState.SELECTED;
    }

    if (researchType) {
      return StepState.WITH_SUCCESS;
    }

    return StepState.DISABLED;
  }

  static getLaboratoryStepState({
    currentStep,
    selectedLaboratory,
  }: {
    currentStep: { code: string | null; name: string | null };
    selectedLaboratory: { id: number | null; name: string | null } | null;
  }) {
    if (currentStep.code === "LABORATORY") {
      return StepState.SELECTED;
    }

    if (selectedLaboratory?.id) {
      return StepState.WITH_SUCCESS;
    }

    return StepState.DISABLED;
  }

  static getPatientStepState({
    currentStep,
    editedPatient,
  }: {
    currentStep: { code: string | null; name: string | null };
    editedPatient: PatientState["editedPatient"];
  }) {
    if (currentStep.code === "PATIENT") {
      return StepState.SELECTED;
    }

    if (editedPatient) {
      return StepState.WITH_SUCCESS;
    }

    return StepState.DISABLED;
  }

  static getReferrerStepState({
    currentStep,
    doctor,
    date,
  }: {
    currentStep: { code: string | null; name: string | null };
    doctor: ReferrerState["doctor"];
    date: ReferrerState["date"];
  }) {
    if (currentStep.code === "REFERRER") {
      return StepState.SELECTED;
    }

    if (doctor && date) {
      return StepState.WITH_SUCCESS;
    }

    return StepState.DISABLED;
  }

  static getClinicalDataStepState({
    currentStep,
  }: {
    currentStep: { code: string | null; name: string | null };
  }) {
    if (currentStep.code === "CLINICAL_DATA") {
      return StepState.SELECTED;
    }

    return StepState.DISABLED;
  }

  static getBiomaterialRegistrationStepState({
    currentStep,
  }: {
    currentStep: { code: string | null; name: string | null };
  }) {
    if (currentStep.code === "BIOMATERIAL_REGISTRATION") {
      return StepState.SELECTED;
    }

    return StepState.DISABLED;
  }

  static getMarkersStepState({
    currentStep,
  }: {
    currentStep: { code: string | null; name: string | null };
  }) {
    if (currentStep.code === "MARKERS") {
      return StepState.SELECTED;
    }

    return StepState.DISABLED;
  }
}
