import { AxiosInstance } from "axios";

export type ApiDoctor = {
  ulid: string; //   ulid: "sir_01J031X6WVDCM2NXMABGGAF2AH";
  email: string; // email: "animats@aol.com";
  priority: null; //   priority: null;

  date_registration: string; //   date_registration: "2024-06-11T10:06:28.159356+03:00";

  user_profile: {
    ulid: string; // ulid: "pro_01J031X7D93QEH1J82PZH98MHG";
    first_name: string; // first_name: "Эрик";

    // middle_name: "";
    middle_name: string;

    // last_name: "Прохоров";
    last_name: string;

    // date_of_birth: null;
    date_of_birth: string | null;

    // phone_number: "375293434580";
    phone_number: string;

    // organization: 15;
    organization: number;

    // organization_custom: "";
    organization_custom: string;

    // specialization: "Диагностика";
    specialization: string;

    // department: 7;
    department: number;

    // subdepartment: "";
    subdepartment: string;

    // title: "Терапевт";
    title: string;
  };

  user_registry_permissions: {
    ulid: "glp_01J031X7DQ3PMXVTQ4FNFCCRXN";
    permission_access: true;
    form_request: true;
    is_validated: true;
    local_admin: false;
    date_of_expiration: null;
    guest: false;
    shadow: false;
    registrar: false;
    doctor: true;
    advisory: false;
    signatory: false;
    lab_assistant: false;
    morphologist_basic: false;
    morphologist_executor: false;
    geneticist_executor: false;
    geneticist_coordinator: false;
  };

  is_staff: false;
} | null;

export class DoctorsService {
  private httpClient: AxiosInstance | null = null;

  constructor(httpClient: any) {
    this.httpClient = httpClient;
  }

  async searchDoctors(props: {
    organization: number;
    lastName?: string;
    firstName?: string;
    middleName?: string;
  }): Promise<Array<ApiDoctor> | undefined> {
    const urlSearchParams = new URLSearchParams("");

    if (props.organization) {
      urlSearchParams.append("organization", String(props.organization));
    }

    if (props.lastName) {
      urlSearchParams.append("last_name", String(props.lastName));
    }

    if (props.firstName) {
      urlSearchParams.append("first_name", String(props.firstName));
    }

    if (props.middleName) {
      urlSearchParams.append("middle_name", String(props.middleName));
    }

    const url = `registry/doctor/search?${urlSearchParams}`;

    return await (
      await this.httpClient?.get<Array<ApiDoctor>>(url)
    )?.data;
  }
}
