import styled from "styled-components";
import { Title } from "../../../../componentsNew/Title";
import { Radio } from "../../../../componentsNew/Radio";
import { useDispatch, useSelector } from "react-redux";
import {
  patientCitizenshipCodeSelector,
  patientsSelector,
  resetSearch,
  searchProcessInitiatedSelector,
  setCitizenshipCode,
} from "../../redux/patient";
import { Constants } from "../../../../constants/Constants";

import { NewRfProfile } from "./components/NewRfProfile";
import { NewForeignProfile } from "./components/NewForeignProfile";
import { FoundRfProfile } from "./components/FoundRfProfile";
import { FoundForeignProfile } from "./components/FoundForeignProfile";
import { RfSearchPersonalData } from "./components/RfSearchPersonalData";
import { ForeignSearchPersonalData } from "./components/ForeignSearchPersonalData";

const citizenships = [
  {
    code: Constants.CITIZENSHIP_RUSSIAN_FEDERATION,
    name: "Гражданин РФ",
  },
  {
    code: Constants.CITIZENSHIP_FOREIGN,
    name: "Иностранный гражданин",
  },
];

export const Patient = () => {
  const dispatch = useDispatch();

  const selectedCitizenshipCode = useSelector(patientCitizenshipCodeSelector);

  const searchProcessInitiated = useSelector(searchProcessInitiatedSelector);
  // const searchProcessResult = useSelector(searchProcessResultSelector);

  const patients = useSelector(patientsSelector);

  const onCitizenshipSelect = (code: string) => {
    dispatch(resetSearch());
    dispatch(setCitizenshipCode({ citizenshipCode: code }));
  };

  const rfSearchPersonalData = <RfSearchPersonalData />;
  const foreignSearchPersonalData = <ForeignSearchPersonalData />;

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Поиск пациента</Title>
      </StyledTitleContainer>

      <StyledCitizenshipsContainer>
        {citizenships.map((citizenship) => (
          <StyledRadioContainer key={citizenship.code}>
            <Radio
              title={
                citizenship.code === Constants.CITIZENSHIP_RUSSIAN_FEDERATION
                  ? "Выберите гражданство"
                  : null
              }
              selected={citizenship.code === selectedCitizenshipCode}
              text={citizenship.name}
              onSelect={() => onCitizenshipSelect(citizenship.code)}
              tooltip={false}
              required={
                citizenship.code === Constants.CITIZENSHIP_RUSSIAN_FEDERATION
                  ? true
                  : false
              }
            />
          </StyledRadioContainer>
        ))}
      </StyledCitizenshipsContainer>

      {selectedCitizenshipCode === Constants.CITIZENSHIP_RUSSIAN_FEDERATION &&
        rfSearchPersonalData}

      {selectedCitizenshipCode === Constants.CITIZENSHIP_FOREIGN &&
        foreignSearchPersonalData}

      {searchProcessInitiated &&
        // !Boolean(searchProcessResult) &&
        !Boolean(patients?.length) &&
        selectedCitizenshipCode ===
          Constants.CITIZENSHIP_RUSSIAN_FEDERATION && <NewRfProfile />}

      {searchProcessInitiated &&
        Boolean(patients?.length) &&
        selectedCitizenshipCode ===
          Constants.CITIZENSHIP_RUSSIAN_FEDERATION && <FoundRfProfile />}

      {searchProcessInitiated &&
        !Boolean(patients?.length) &&
        selectedCitizenshipCode === Constants.CITIZENSHIP_FOREIGN && (
          <NewForeignProfile />
        )}

      {searchProcessInitiated &&
        Boolean(patients?.length) &&
        selectedCitizenshipCode === Constants.CITIZENSHIP_FOREIGN && (
          <FoundForeignProfile />
        )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;

  padding-bottom: 200px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
  /* margin-bottom: 32px; */
`;

const StyledCitizenshipsContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-end;

  gap: 16px;
`;

const StyledRadioContainer = styled.div`
  min-width: 340px;
`;
