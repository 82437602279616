import styled from "styled-components";
import { Colors } from "../../styles/themeNew";

type Type = "error" | "warning";

type Props = {
  type: Type;
  title: string;
  description: string;
};

const getIcon = (type: Type) => {
  if (type === "warning")
    return (
      <svg
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.2839 5.3152L17.1291 10.4218C17.9825 11.9534 18.6059 13.0735 18.9847 13.9651C19.364 14.8579 19.4643 15.4479 19.3534 15.9373C19.2315 16.476 18.9588 16.9655 18.5696 17.3442C18.216 17.6884 17.6726 17.8934 16.7368 17.9994C15.8024 18.1053 14.5545 18.1059 12.8478 18.1059H7.15739C5.45071 18.1059 4.20289 18.1053 3.2684 17.9994C2.33261 17.8934 1.78935 17.6884 1.43569 17.3442C1.04645 16.9655 0.77377 16.476 0.651838 15.9373C0.541055 15.4479 0.641282 14.8579 1.02058 13.965C1.39936 13.0735 2.02279 11.9534 2.87613 10.4218L5.72137 5.31519C6.57472 3.78361 7.1991 2.6641 7.75478 1.87841C8.31128 1.09163 8.75434 0.706653 9.21879 0.561349C9.72996 0.401425 10.2753 0.401426 10.7865 0.561349C11.2509 0.706653 11.694 1.09164 12.2505 1.87842C12.8061 2.6641 13.4305 3.78361 14.2839 5.3152Z"
          stroke="#EC9000"
          strokeWidth="0.839428"
        />
        <circle cx="9.98787" cy="14.3101" r="0.839428" fill="#EC9000" />
        <line
          x1="9.95878"
          y1="5.49784"
          x2="9.95878"
          y2="11.3738"
          stroke="#EC9000"
          strokeWidth="0.839428"
          strokeLinecap="round"
        />
      </svg>
    );
};

export const Notification = (props: Props) => {
  return (
    <StyledContainer>
      <StyledLeftContainer>{getIcon(props.type)}</StyledLeftContainer>
      <StyledRightContainer>
        <StyledTitle>{props.title}</StyledTitle>
        <StyledDescription>{props.description}</StyledDescription>
      </StyledRightContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  border-radius: 6px;
  background: var(--Orange-50, #fffbeb);
`;

const StyledTitle = styled.p`
  font-family: 'Inter';
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 500;
  color: ${Colors.MONOCHROM[950]};
`
const StyledDescription = styled.p`
  font-family: 'Inter';
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${Colors.MONOCHROM[800]};
`

const StyledLeftContainer = styled.div``;
const StyledRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;