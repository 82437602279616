import { useDispatch } from "react-redux";
import { useHttpClient } from "../../../hooks/useHttpClient";
import { setMarkers } from "../redux/markers";
import { MarkersService } from "../../../services/Markers";

export const useMarkers = () => {
  const dispatch = useDispatch();

  const httpClient = useHttpClient();

  const searchMarkers = async (props: { localization: number }) => {
    const markersService = new MarkersService(httpClient);

    const _markers = await markersService.searchMarkers(props);

    if (_markers?.length) {
      dispatch(setMarkers(_markers));
    }
  };

  return {
    searchMarkers,
  };
};
