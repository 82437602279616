import styled from "styled-components";
import { Colors } from "../../styles/themeNew";

type Props = {
  title?: string;
  required?: boolean;
  placeholder?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Textarea = (props: Props) => {
  return (
    <StyledContainer>
      {props.title && (
        <StyledLabel htmlFor="textarea">
          {props.title}{" "}
          {props.required && (
            <StyledAsteriskContainer>*</StyledAsteriskContainer>
          )}
        </StyledLabel>
      )}

      <StyledMainContainer>
        <StyledTextarea
          //   type="date"
          id="textarea"
          name="textarea_name"
          placeholder={props?.placeholder || ""}
          // value="2018-07-22"
          // min="2018-01-01"
          // max="2018-12-31"
        />
      </StyledMainContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const StyledMainContainer = styled.div`
  width: 100%;
  /* height: 60px; */
  box-sizing: border-box;
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${Colors.MONOCHROM[950]};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;

  gap: 4px;
`;

const StyledTextarea = styled.textarea`
  height: 100%;
  margin: 0;
  padding: 0;

  padding: 16px 16px;

  /* min-height: 60px; */

  border-radius: 6px;
  border: 1px solid ${Colors.MONOCHROM[200]};
  background: transparent;

  box-sizing: border-box;

  width: 100%;

  color: ${Colors.MONOCHROM[950]};

  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    color: ${Colors.MONOCHROM[400]};
  }
`;

const StyledAsteriskContainer = styled.div`
  color: ${Colors.RED[500]};
  font-size: 12px;
  font-weight: 400;
`;
