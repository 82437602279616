import { useSelector } from "react-redux";

import { getTokens } from "../store/auth/selectors";
import axiosInstance from "../api/axiosInstance";

export const useHttpClient = () => {
  const tokens = useSelector(getTokens);

  return axiosInstance(tokens?.access, null, { withoutCaseMiddleware: true });
};
