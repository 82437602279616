import styled from "styled-components";
import { Colors } from "../../styles/themeNew";
import { useState } from "react";

type Props = {
  title?: string;
  placeholder?: string;
  required?: boolean;

  options: Array<{ label: string; value: string; selected: boolean }>;

  onSelect: (option: { label: string; value: string }) => void;
};

export const Select = (props: Props) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const selectedOption = props.options.find((option) => option.selected);

  return (
    <StyledContainer
      onClick={() => {
        setDropdownVisible((value) => !value);
      }}
    >
      {props.title && (
        <StyledLabel htmlFor="textarea">
          {props.title}{" "}
          {props.required && (
            <StyledAsteriskContainer>*</StyledAsteriskContainer>
          )}
        </StyledLabel>
      )}

      <StyledMainContainer>
        {!Boolean(selectedOption) && props.placeholder && (
          <StyledPlaceholderContainer>
            {props.placeholder}
          </StyledPlaceholderContainer>
        )}

        {Boolean(selectedOption) && (
          <StyledSelectedOptionContainer>
            {selectedOption?.label}
          </StyledSelectedOptionContainer>
        )}

        <StyledIconContainer>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.2081 14.6224C11.6008 15.1259 12.3992 15.1259 12.7919 14.6224L16.8074 9.47397C17.2843 8.86257 16.8209 8 16.0156 8H7.98442C7.17909 8 6.71569 8.86257 7.19256 9.47397L11.2081 14.6224Z"
              fill="#25272C"
            />
          </svg>
        </StyledIconContainer>
      </StyledMainContainer>

      {dropdownVisible && (
        <StyledDropdownContainer withTitle={Boolean(props.title)}>
          {props.options.map((option) => (
            <StyledOptionContainer
              key={option.value}
              selected={option.selected}
              onClick={() => props.onSelect(option)}
            >
              {option.label}
            </StyledOptionContainer>
          ))}
        </StyledDropdownContainer>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  position: relative;
  /* z-index: -1; */
`;

const StyledPlaceholderContainer = styled.div`
  color: ${Colors.MONOCHROM[400]};

  font-size: 16px;

  font-weight: 400;
`;

const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: 18px 16px;

  /* display: flex;
  flex-direction: row;

  height: 60px;
  box-sizing: border-box; */

  border-radius: 6px;
  border: 1px solid ${Colors.MONOCHROM[200]};
  background: transparent;

  box-sizing: border-box;

  position: relative;
  /* z-index: 1; */
`;

const StyledDropdownContainer = styled.div<{ withTitle: boolean }>`
  position: absolute;

  z-index: 5;

  width: 100%;
  height: 160px;

  top: ${(props) => (props.withTitle ? "88px" : "68px")};
  left: 0;

  border-radius: 10px;
  border: 1px solid ${Colors.BRAND[500]};
  background: white;

  padding: 16px;
  box-sizing: border-box;

  gap: 10px;

  display: flex;
  flex-direction: column;

  overflow-y: scroll;

  /* z-index: 100000000; */

  &::-webkit-scrollbar {
    /* width: 1em;
    width: 4px;
    margin-right: 8px;
    height: 80%; */

    width: 20px;
  }

  &::-webkit-scrollbar-track {
    /* margin-right: 2px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 6px; */
  }

  &::-webkit-scrollbar-thumb {
    /* background-color: darkgrey;
    outline: 1px solid slategrey; */

    background: ${Colors.BRAND[500]};
    background-clip: content-box;
    border: 8px solid transparent;
    border-radius: 6px;
  }
`;

const StyledOptionContainer = styled.div<{ selected: boolean }>`
  background: transparent;
  color: ${Colors.MONOCHROM[950]};

  &:hover {
    background: ${Colors.BRAND[50]};
    color: ${Colors.MONOCHROM[950]};
  }

  background: ${(props) => props.selected && Colors.BRAND[500]};

  color: ${(props) => props.selected && "white"};

  /* width: 100%; */
  padding: 6px 16px;

  min-height: 40px;

  border-radius: 6px;

  font-size: 14px;

  font-weight: 400;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledSelectedOptionContainer = styled.div`
  color: ${Colors.MONOCHROM[950]};

  font-size: 16px;

  font-weight: 400;
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${Colors.MONOCHROM[950]};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;

  gap: 4px;
`;

const StyledAsteriskContainer = styled.div`
  color: ${Colors.RED[500]};
  font-size: 12px;
  font-weight: 400;
`;
