import styled from "styled-components";
import { Textarea } from "../../../../../../componentsNew/Textarea";
import { useDispatch, useSelector } from "react-redux";
import {
  previousResultsSelector,
  setPreviousResults,
} from "../../../../redux/clinicalData";

export const Results = () => {
  const dispatch = useDispatch();

  const previousResults = useSelector(previousResultsSelector);

  return (
    <StyledContainer>
      <Textarea
        title="Результаты предыдущих патолого-анатомических исследований"
        required={true}
        placeholder="Диагноз, указанный направителем, должен включать стадию, 
информацию о проведенном лечении и иные релевантные данные"
        value={previousResults || ""}
        onChange={(event) => {
          dispatch(setPreviousResults(event.target.value));
        }}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div``;
