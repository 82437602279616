export enum StepState {
    SELECTED = 'SELECTED',
    DISABLED = 'DISABLED',
    WITH_SUCCESS = 'WITH_SUCCESS',
    WITH_WARNING = 'WITH_WARNING',
    WITH_ERROR = 'WITH_ERROR',
    IN_PROCESS = 'IN_PROCESS'
}

export type Step = {
    code: string;
    name: string;
    state: StepState;
}
