import { combineReducers } from "redux";
import molecularProfilingReducer from "./molecularProfiling";
import organizationsReducer from "./organizations";
import localizationsReducer from "./localizations";
import countriesReducer from "./countries";
import referralReducer from "./referral";
import patientReducer from "./patient";
import serviceUsersReducer from "./serviceUsers";
import authReducer from "./auth";
import markersReducer from "./markers";
import analysisReducer from "./analysis";
import researchReducer from "./research";
import mkb10Reducer from "./mkb10";
import alertsReducer from "./notifyAlerts";
import molProConclusionReducer from "./molProConclusion";
import { createReferralPageNewReducer } from "../containers/CreateReferralPageNew/redux/createReferralPageNew";

const rootReducer = combineReducers({
  molecularProfiling: molecularProfilingReducer,
  molProConclusion: molProConclusionReducer,
  organizations: organizationsReducer,
  localizations: localizationsReducer,
  countries: countriesReducer,
  referral: referralReducer,
  patient: patientReducer,
  serviceUsers: serviceUsersReducer,
  auth: authReducer,
  markers: markersReducer,
  analysis: analysisReducer,
  research: researchReducer,
  mkb10: mkb10Reducer,
  notifyAlerts: alertsReducer,
  createReferralPageNew: createReferralPageNewReducer,
});

export type IAppState = ReturnType<typeof rootReducer>;

export default rootReducer;
