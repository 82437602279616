import { useDispatch, useSelector } from "react-redux";
import { useHttpClient } from "../../../hooks/useHttpClient";

import { DoctorsService } from "../../../services/Doctors";
import {
  doctorsSelector,
  setDoctor,
  setDoctors,
  setSearchProcess,
} from "../redux/referrer";

export const useDoctors = () => {
  const dispatch = useDispatch();

  const httpClient = useHttpClient();

  const doctors = useSelector(doctorsSelector);

  const searchDoctors = async (props: {
    firstName?: string;
    lastName?: string;
    middleName?: string;
    organization: number;
  }) => {
    dispatch(setSearchProcess(true));

    const doctorsService = new DoctorsService(httpClient);

    const _doctors = await doctorsService.searchDoctors({
      firstName: props.firstName,
      lastName: props.lastName,
      middleName: props.middleName,
      organization: props.organization,
    });

    if (_doctors) {
      dispatch(setDoctors(_doctors));

      if (_doctors?.length === 1) {
        dispatch(setDoctor(_doctors[0]));
      }
    }

    if (!_doctors?.length) {
      dispatch(setDoctors([]));
      dispatch(setDoctor(null));


    }
  };

  return {
    doctors,
    searchDoctors,
  };
};
