import { useSelector } from "react-redux";
import { currentStepSelector } from "../../redux/currentStep";

import { ResearchType } from "./../../components/ResearchType";
import { Laboratory } from "./../../components/Laboratory";
import { Patient } from "./../../components/Patient";
import { Referrer } from "./../../components/Referrer";
import { ClinicalData } from "./../../components/ClinicalData";
import { BiomaterialRegistration } from "./../../components/BiomaterialRegistration";
import { Markers } from "./../../components/Markers";

export const Content = () => {
  const currentStep = useSelector(currentStepSelector);

  return (
    <>
      {currentStep.code === "RESEARCH_TYPE" && <ResearchType />}
      {currentStep.code === "LABORATORY" && <Laboratory />}
      {currentStep.code === "PATIENT" && <Patient />}
      {currentStep.code === "REFERRER" && <Referrer />}
      {currentStep.code === "CLINICAL_DATA" && <ClinicalData />}
      {currentStep.code === "BIOMATERIAL_REGISTRATION" && (
        <BiomaterialRegistration />
      )}
      {currentStep.code === "MARKERS" && <Markers />}
    </>
  );
};
