import styled from "styled-components";
import { Title } from "../../../../componentsNew/Title";
import { Radio } from "../../../../componentsNew/Radio";
import { Select } from "../../../../componentsNew/Select";
import { Datepicker } from "../../../../componentsNew/Datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  dateSelector,
  materialSelector,
  materialTypeSelector,
  // obtainingSelector,
  sampleTypeSelector,
  setDate,
  setMaterial,
  setMaterialType,
  setObtaining,
  setSampleType,
} from "../../redux/biomaterialRegistration";
import { newRfProfileSelector } from "../../redux/patient";
import { getOrganizations } from "../../../../store/organizations/selectors";

import { Notification } from "../../../../componentsNew/Notification";

export const BiomaterialRegistration = () => {
  const dispatch = useDispatch();

  const material = useSelector(materialSelector);
  const materialType = useSelector(materialTypeSelector);
  // const obtaining = useSelector(obtainingSelector);
  const sampleType = useSelector(sampleTypeSelector);
  const date = useSelector(dateSelector);
  const newRfProfile = useSelector(newRfProfileSelector);
  const clinics = useSelector(getOrganizations);

  const procedureBiopsyList = [
      { label: "операционная биопсия", value: "surgical_biopsy", selected: false },
      { label: "операционный материал", value: "surgical_material", selected: false },
      { label: "самопроизвольно отделившиеся фрагменты тканей", value: "spontaneously_separated_tissue_fragments", selected: false }
  ];

  const biopsyList = [
      { label: "эндоскопическая биопсия", value: "endoscopic_biopsy", selected: false },
      { label: "пункционная биопсия", value: "puncture_biopsy", selected: false },
      { label: "аспирационная биопсия", value: "aspiration_biopsy", selected: false },
      { label: "инцизионная биопсия", value: "micisional_biopsy", selected: false },
      { label: "самопроизвольно отделившиеся фрагменты тканей", value: "spontaneously_separated_tissue_fragments", selected: false }
  ];

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Регистрация биоматериала</Title>
      </StyledTitleContainer>
      <Notification type="warning" title="Внимание!" description="Ошибка регистрации номеров блоков может быть основанием для отмены направления"></Notification>
      <StyledRadioContainer>
        <Radio
          title="Вид материала"
          selected={material === "Парафиновый блок + Гист. стекло"}
          text="Парафиновый блок + Гист. стекло"
          onSelect={() => {
            dispatch(setMaterial("Парафиновый блок + Гист. стекло"));
          }}
          tooltip={false}
          required={true}
        />
      </StyledRadioContainer>

      <StyledRadioGroupContainer>
        <StyledRadioContainer>
          <Radio
            title="Тип материала"
            selected={materialType === "Операционный"}
            text="Операционный"
            onSelect={() => {
              dispatch(setMaterialType("Операционный"));
            }}
            tooltip={false}
            required={true}
          />
        </StyledRadioContainer>

        <StyledRadioContainer>
          <Radio
            selected={materialType === "Биопсийный"}
            text="Биопсийный"
            onSelect={() => {
              dispatch(setMaterialType("Биопсийный"));
            }}
            tooltip={false}
            required={true}
          />
        </StyledRadioContainer>
      </StyledRadioGroupContainer>

      <StyledSelectContainer>
        <Select
          title="Способ получения"
          required={true}
          placeholder="Выберите значение"
          options={materialType === "Операционный" ? procedureBiopsyList : biopsyList}
          onSelect={(option) => {
              (
                  materialType === "Операционный"
                  ? procedureBiopsyList
                  : biopsyList
              )
                  .find((opt) => {
                      if (opt.value === option.value) {
                          opt.selected = !opt.selected;
                          return true;
                      } else {
                          opt.selected = false;
                      }
                      return false;
                  });
              dispatch(setObtaining(option?.value));
          }}
        />
      </StyledSelectContainer>

      <StyledRadioGroupContainer>
        <StyledRadioContainer>
          <Radio
            title="Тип образца"
            selected={sampleType === "Первичная опухоль"}
            text="Первичная опухоль"
            onSelect={() => {
              dispatch(setSampleType("Первичная опухоль"));
            }}
            tooltip={false}
            required={true}
          />
        </StyledRadioContainer>

        <StyledRadioContainer>
          <Radio
            selected={sampleType === "Метастаз"}
            text="Метастаз"
            onSelect={() => {
              dispatch(setSampleType("Метастаз"));
            }}
            tooltip={false}
            required={true}
          />
        </StyledRadioContainer>
      </StyledRadioGroupContainer>

      <StyledDatepickerContainer>
        <Datepicker
          required={true}
          title="Дата забора материала"
          placeholder=""
          value={date || ""}
          onChange={(event) => {
            dispatch(setDate(event.target.value));
          }}
        />
      </StyledDatepickerContainer>

      <StyledRowContainer>
          <Title level="3">Зарегистрируйте ОРИГИНАЛЬНЫЕ номера блоков и стекол:</Title>
      </StyledRowContainer>
      <StyledInputContainer>
          <Select
              title="Учреждение, присвоившее оригинальные номера блокам, стеклам"
              placeholder="--"
              required={true}
              options={clinics.map((clinic) => ({
                  label: clinic.nameLocalRus,
                  value: String(clinic.id),
                  selected: `${clinic.id}` === `${newRfProfile?.card?.hospital?.value}`,
              }))}
              onSelect={() => alert('clinic.nameLocalRus')}
          />
        </StyledInputContainer>

    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;

  padding-bottom: 200px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
`;

const StyledRadioContainer = styled.div`
  width: 100%;
`;

const StyledRadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledSelectContainer = styled.div`
  width: 100%;
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 16px;
`;

const StyledInputContainer = styled.div`
  width: 100%;
`;

const StyledDatepickerContainer = styled.div``;
