import styled from "styled-components";
import { Colors } from "../../styles/themeNew";

type Props = {
  type: "PRIMARY" | "SECONDARY";
  icon?: any;
  text: string;

  onClick: () => void;

  size?: "XL" | "L" | "M" | "S" | "XS";

  iconPosition?: "left" | "right";

  loading?: boolean;

  disabled?: boolean;

  color?: string;
};

export const Button = (props: Props) => {
  return (
    <StyledContainer
      type={props.type}
      onClick={props.disabled ? () => {} : props.onClick}
      size={props.size}
      themeColor={props.color}
      disabled={props.disabled}
    >
      {Boolean(props.icon) && props.iconPosition === "left" && props.icon}
      {props.text}
      {Boolean(props.icon) && props.iconPosition === "right" && props.icon}
    </StyledContainer>
  );
};

const getBorderColor = (disabled?: boolean, themeColor?: string) => {
  if (disabled) {
    return Colors.MONOCHROM[200];
  }

  if (themeColor) {
    return "white";
  }

  return Colors.BRAND[500];
};

const getBackgroundColor = (
  type: "PRIMARY" | "SECONDARY",
  disabled?: boolean,
  themeColor?: string
) => {
  if (disabled) {
    return Colors.MONOCHROM[200];
  }

  if (themeColor) {
    return themeColor;
  }

  if (type === "PRIMARY") {
    return Colors.BRAND[500];
  }

  return "transparent";
};

const getTextColor = (
  type: "PRIMARY" | "SECONDARY",
  disabled?: boolean,
  themeColor?: string
) => {
  if (type === "PRIMARY") {
    return "white";
  }

  if (disabled) {
    return Colors.MONOCHROM[400];
  }

  if (themeColor) {
    return "white";
  }

  return Colors.BRAND[500];
};

const getHeight = (size?: "XL" | "L" | "M" | "S" | "XS") => {
  if (size === "XL") {
    return "52px";
  }

  if (size === "L") {
    return "40px";
  }

  if (size === "M") {
    return "32px";
  }

  if (size === "S") {
    return "26px";
  }

  if (size === "XS") {
    return "22px";
  }

  return "52px";
};

const StyledContainer = styled.div<{
  type: "PRIMARY" | "SECONDARY";
  size?: "XL" | "L" | "M" | "S" | "XS";
  disabled?: boolean;
  themeColor?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 16px;

  height: ${(props) => getHeight(props.size)};

  border-radius: 6px;
  border: 1px solid
    ${(props) => getBorderColor(props.disabled, props.themeColor)};
  background: ${(props) =>
    getBackgroundColor(props.type, props.disabled, props.themeColor)};
  color: ${(props) =>
    getTextColor(props.type, props.disabled, props.themeColor)};

  font-size: 16px;
  font-weight: 600;

  cursor: pointer;

  box-sizing: border-box;
`;
