import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../../store";
import { ApiDoctor } from "../../../services/Doctors";

export type ReferrerState = {
  searchProcess: boolean;
  searchLastName: string | null;

  doctors: Array<ApiDoctor>;

  doctor: ApiDoctor | null;

  date: string | null;
};

const initialState: ReferrerState = {
  searchProcess: false,
  searchLastName: null,
  doctors: [],
  doctor: null,
  date: null,
};

export const referrerSlice = createSlice({
  name: "referrer",
  initialState,
  reducers: {
    setSearchProcess: (state, action: PayloadAction<boolean>) => {
      state.searchProcess = action.payload;
    },

    setSearchLastName: (state, action: PayloadAction<string>) => {
      state.searchLastName = action.payload;
    },

    setDoctors: (state, action: PayloadAction<Array<ApiDoctor>>) => {
      state.doctors = action.payload;
    },

    setDoctor: (state, action: PayloadAction<ApiDoctor>) => {
      state.doctor = action.payload;
    },

    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
  },
});

export const {
  setSearchProcess,
  setSearchLastName,
  setDoctors,
  setDoctor,
  setDate,
} = referrerSlice.actions;

export const referrerReducer = referrerSlice.reducer;

const getState = (state: IAppState) => state.createReferralPageNew;

export const searchProcessSelector = createSelector(
  getState,
  (state) => state.referrer.searchProcess
);

export const searchLastNameSelector = createSelector(
  getState,
  (state) => state.referrer.searchLastName
);

export const doctorsSelector = createSelector(
  getState,
  (state) => state.referrer.doctors
);

export const doctorSelector = createSelector(
  getState,
  (state) => state.referrer.doctor
);

export const dateSelector = createSelector(
  getState,
  (state) => state.referrer.date
);
