import { AxiosInstance } from "axios";

export type ApiLaboratory = {
  id: number; // id: 25;

  ulid: string; // ulid: "org_01J8D2YT5TJEN32EG3Y6RSM9CN";

  email: string; // email: "lab.1@mail.ru";

  organization_department: Array<any>; // organization_department: [];
  // organizationDepartment: Array<any>; // organization_department: [];

  name_official: string; // name_official: "Lab 1";
  // nameOfficial: string;

  name_official_rus: string; // name_official_rus: "Лаборатория 1";
  // nameOfficialRus: string; // name_official_rus: "Лаборатория 1";

  name_local_rus: string; // name_local_rus: "Лаборатория 1";
  // nameLocalRus: string; // name_local_rus: "Лаборатория 1";

  description: string; // description: "";
  type: string; // type: "LAB";
};

export class LaboratoriesService {
  private httpClient: AxiosInstance | null = null;

  constructor(httpClient: any) {
    this.httpClient = httpClient;
  }

  async searchLaboratories(): Promise<Array<ApiLaboratory> | undefined> {
    const url = `account/organization/list/?type=LAB`;

    return await (
      await this.httpClient?.get<Array<ApiLaboratory>>(url)
    )?.data;
  }
}
