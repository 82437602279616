import { useDispatch, useSelector } from "react-redux";
import { useHttpClient } from "../../../hooks/useHttpClient";
import { LocalizationsService } from "../../../services/Localizations";
import { setLocalizations } from "../redux/clinicalData";
import { useEffect } from "react";
import { LaboratoriesService } from "../../../services/Laboratories";
import { setLaboratories } from "../redux/laboratory";
import { getTokens } from "../../../store/auth/selectors";

export const useBackgroundData = () => {
  const dispatch = useDispatch();

  const httpClient = useHttpClient();

  const tokens = useSelector(getTokens);

  const searchLocalizations = async () => {
    const localizationService = new LocalizationsService(httpClient);

    const _localizations = await localizationService.searchLocalizations();

    if (_localizations?.length) {
      dispatch(setLocalizations(_localizations));
    }
  };

  const searchLaboratories = async () => {
    const laboratoriesService = new LaboratoriesService(httpClient);

    const laboratories = await laboratoriesService.searchLaboratories();

    if (laboratories?.length) {
      dispatch(setLaboratories(laboratories));
    }
  };

  const effect = async () => {
    await searchLaboratories();
    await searchLocalizations();
  };

  useEffect(() => {
    if (!tokens?.access) {
      return;
    }

    effect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens?.access]);
};
