import styled, {css} from 'styled-components';
import Button from '../../shared/Button';
import { Link } from 'react-router-dom';
import FormTitle from '../../shared/FormTitle';
import Tooltip from "../../shared/Tooltip";

interface IDescription {
    invalidToken?: boolean;
}
export const TFormTitle = styled(FormTitle)`
  padding: 0 52px;
  margin-bottom: 30px;
  font-size: 24px;
`;

export const TFieldsContainer = styled.div`
  & > * {
    margin-bottom: 20px;
  }
  & > *:last-child {
    margin-bottom: 78px;
    margin-top: 72px;
  }

  padding: 0 52px;
`;

export const TBottomLinkWr = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 49px;
  width: 100%;
`;

export const TLink = styled(Link)`
  color: ${({ theme }) => theme.colors.gray[500]};
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  cursor: pointer;
  
  &:hover {
    color: ${({ theme }) => theme.colors.product.primaryHover};
  }
`;

export const TButton = styled(Button)<{isError?: boolean}>`
  font-size: 20px;
  height: 44px;
  width: 100%;
`;

export const TDescription = styled.p<IDescription>`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray[500]};
  margin: 0 52px 30px;
  
  ${({invalidToken}) => invalidToken && css`
    width: 439px;
    height: 96px;
    margin: 0 15px 54px;
  `}
`;

export const TTooltipPasswordPrompt = styled(Tooltip)`
  padding: 17px 30px !important;
  width: max-content;
  max-width: 303px;
`
