import { PropsWithChildren } from "react";
import styled from "styled-components";
import { Colors } from "../../styles/themeNew";

type Props = {
};

export const Description = (props: PropsWithChildren<Props>) => {
  return (
    <StyledContainer>{props.children}</StyledContainer>
  );
};

// const getFontSize = (level: string) => {
//   if (level === "1") {
//     return "24px";
//   }

//   if (level === "2") {
//     return "20px";
//   }

//   if (level === "3") {
//     return "18px";
//   }

//   return "16px";
// };

 /* font-size: ${(props) => getFontSize(props.level)}; */
// const StyledContainer = styled.div<{ level: string }>`
const StyledContainer = styled.div`
 
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: ${Colors.MONOCHROM[400]};
`;
