import styled from "styled-components";
import { Textarea } from "../../../../../../componentsNew/Textarea";
import { useDispatch, useSelector } from "react-redux";
import {
  setTreatment,
  treatmentSelector,
} from "../../../../redux/clinicalData";

export const Treatment = () => {
  const dispatch = useDispatch();

  const treatment = useSelector(treatmentSelector);

  return (
    <StyledContainer>
      <Textarea
        title="Предоперационное лечение"
        required={true}
        placeholder="Диагноз, указанный направителем, должен включать стадию, 
информацию о проведенном лечении и иные релевантные данные"
        value={treatment || ""}
        onChange={(event) => {
          dispatch(setTreatment(event.target.value));
        }}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div``;
