import React from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";

import {
    editedPatientSelector,
    patientsSelector,
    selectedPatientConfirmedSelector,
    selectedPatientUlidSelector,
    setEditedPatient,
    setSelectedPatientConfirmed,
    setSelectedPatientUlid,
} from "../../../../redux/patient";

import { Title } from "../../../../../../componentsNew/Title";
import { Input } from "../../../../../../componentsNew/Input";
import { Datepicker } from "../../../../../../componentsNew/Datepicker";
import { Description } from "../../../../../../componentsNew/Description";

import { Button } from "../../../../../../componentsNew/Button";
import { Radio } from "../../../../../../componentsNew/Radio";

import { Colors } from "../../../../../../styles/themeNew";

export const FoundForeignProfile = () => {
    const dispatch = useDispatch();

    const patients = useSelector(patientsSelector);

    const selectedPatientUlid = useSelector(selectedPatientUlidSelector);

    const selectedPatientConfirmed = useSelector(
        selectedPatientConfirmedSelector
    );

    const editedPatient = useSelector(editedPatientSelector);

    const getPatient = () => {
        if (selectedPatientUlid && selectedPatientConfirmed) {
            return patients.find((_patient) => _patient.ulid === selectedPatientUlid);
        }

        if (patients?.length === 1) {
            return patients[0];
        }

        return null;
    };

    const patient = getPatient();

    const onPatientSelect = (patientUlid: string) => {
        dispatch(setSelectedPatientUlid(patientUlid));
    };

    const onPatientConfirm = () => {
        dispatch(
            setEditedPatient({
                ulid: patient?.ulid,
                idPassport: patient?.id_passport,
                address: patient?.address,
                countryId: patient?.country,
                countryName: patient?.country_name,
                countryAlphaCode: patient?.country_alpha,
                gender: patient?.gender,
                lastName: patient?.last_name,
                firstName: patient?.first_name,
                patronymic: patient?.middle_name,
                oms: patient?.oms_number,
                snils: patient?.snils_number,
                birthdate: patient?.date_of_birth,
                fullName: patient?.full_name,

                ambulatoryCard: {
                    number: patient?.ambulatory_cards[0]?.number,
                    organizationId: patient?.ambulatory_cards[0]?.organization_id,
                    organizationName: patient?.ambulatory_cards[0]?.custom_organization,
                },

                confirmed: true,
            })
        );
    };

    if (!patient && patients?.length > 1) {
        return (
            <StyledContainer>
                <StyledTitleContainer>
                    <Title level="2">Найдено несколько пациентов</Title>
                </StyledTitleContainer>

                <StyledFoundPatientListContainer>
                    {patients.map((_patient) => (
                        <React.Fragment key={_patient.ulid}>
                            <Radio
                                title={"ФИО"}
                                selected={_patient.ulid === selectedPatientUlid}
                                text={`${_patient.id_passport} ${_patient.full_name}`}
                                onSelect={() => onPatientSelect(_patient.ulid)}
                                tooltip={false}
                                required={true}
                            />
                        </React.Fragment>
                    ))}
                </StyledFoundPatientListContainer>

                <StyledSelectButtonContainer>
                    <Button
                        size="L"
                        type="PRIMARY"
                        text="Выбрать пациента"
                        onClick={() => {
                            dispatch(setSelectedPatientConfirmed(true));
                        }}
                        color={Colors.GREEN[500]}
                    />
                </StyledSelectButtonContainer>
            </StyledContainer>
        );
    }

    const getGenderName = (code?: string) => {
        if (code === "Female") {
            return "Женский";
        }

        if (code) {
            return "Мужской";
        }

        return "";
    };

    return (
        <StyledContainer>
            <StyledTitleContainer>
                <Title level="2">Пациент найден</Title>
            </StyledTitleContainer>

            <StyledRowContainer>
                <Description>
                    Для продолжения работы подтвердите профиль пациента. При необходимости
                    добавьте амбулаторную карту
                </Description>
            </StyledRowContainer>

            <StyledRowContainer>
                <StyledInputContainer>
                    <Input
                        title="Гражданство"
                        required={true}
                        placeholder=""
                        value={patient?.country_name || ""}
                        // onChange={(event) => {
                        //   console.log("_____Change", event);
                        //   dispatch(setNewRfProfileCitizenship(event.target.value));
                        // }}
                        onChange={() => {}}
                        disabled={editedPatient?.confirmed}
                    />
                </StyledInputContainer>
            </StyledRowContainer>

            <StyledRowContainer>
                <StyledInputContainer>
                    <Input
                        title="Серия и номер паспорта"
                        required={true}
                        placeholder=""
                        value={patient?.id_passport || ""}
                        // onChange={(event) => {
                        //   dispatch(setNewRfProfilePassport(event.target.value));
                        // }}
                        onChange={() => {}}
                        disabled={editedPatient?.confirmed}
                    />
                </StyledInputContainer>
            </StyledRowContainer>

            <StyledRowContainer>
                <StyledInputContainer>
                    <Input
                        title="Адрес регистрации"
                        required={true}
                        placeholder=""
                        value={patient?.address || ""}
                        onChange={(event) => {
                            //   dispatch(setNewRfProfileAddress(event.target.value));
                        }}
                        disabled={editedPatient?.confirmed}
                    />
                </StyledInputContainer>
            </StyledRowContainer>

            <StyledRowContainer>
                <StyledInputContainer2>
                    <Input
                        title="Номер полиса ОМС"
                        required={true}
                        placeholder=""
                        value={patient?.oms_number || ""}
                        onChange={(event) => {
                            //   dispatch(setNewRfProfileOms(event.target.value));
                        }}
                        disabled={editedPatient?.confirmed}
                    />
                </StyledInputContainer2>

                <StyledInputContainer2>
                    <Input
                        title="Номер СНИЛС"
                        required={true}
                        placeholder=""
                        value={patient?.snils_number || ""}
                        onChange={(event) => {
                            //   dispatch(setNewRfProfileSnils(event.target.value));
                        }}
                        disabled={editedPatient?.confirmed}
                    />
                </StyledInputContainer2>
            </StyledRowContainer>

            <StyledRowContainer>
                <StyledInputContainer>
                    <Input
                        title="ФИО"
                        required={true}
                        placeholder=""
                        value={patient?.full_name || ""}
                        onChange={(event) => {
                            //   dispatch(setNewRfProfileAddress(event.target.value));
                        }}
                        disabled={editedPatient?.confirmed}
                    />
                </StyledInputContainer>
            </StyledRowContainer>

            <StyledRowContainer>
                <StyledInputContainer2>
                    <Input
                        title="Пол"
                        required={true}
                        placeholder=""
                        value={getGenderName(patient?.gender) || ""}
                        onChange={(event) => {
                            //   dispatch(setNewRfProfilePatronymic(event.target.value));
                        }}
                        disabled={editedPatient?.confirmed}
                    />
                </StyledInputContainer2>

                <StyledInputContainer2>
                    <Datepicker
                        required={true}
                        title="Дата рождения2"
                        placeholder=""
                        value={patient?.date_of_birth || ""}
                        onChange={(event) => {
                            //   dispatch(setNewRfProfileBirthdate(event.target.value));
                        }}
                        disabled={editedPatient?.confirmed}
                    />
                </StyledInputContainer2>
            </StyledRowContainer>

            <StyledAmbulatoryCardContainer>
                <StyledRowContainer style={{ marginBottom: "16px" }}>
                    <Title level="3">Амбулаторная карта</Title>
                </StyledRowContainer>

                <StyledRowContainer style={{ marginBottom: "8px" }}>
                    <Description>
                        Убедитесь, что выбранная амбулаторная карта зарегистрирована в
                        учреждении направителя
                    </Description>
                </StyledRowContainer>

                {patient?.ambulatory_cards?.length && (
                    <StyledAmbulatoryCardsContainer>
                        {patient?.ambulatory_cards?.map((ambulatoryCard) => (
                            <StyledRowContainer2 key={ambulatoryCard.number}>
                                <StyledInputContainer2>
                                    <Input
                                        title="Номер амбулаторной карты"
                                        required={true}
                                        placeholder=""
                                        // value={patient?.ambulatory_cards || ""}
                                        value={ambulatoryCard.number}
                                        onChange={(event) => {
                                            // dispatch(setNewRfProfileCardNumber(event.target.value));
                                        }}
                                        disabled={editedPatient?.confirmed}
                                    />
                                </StyledInputContainer2>

                                {/* <StyledInputContainer2>
            <Select
              placeholder="УЗ"
              required={true}
              options={clinics.map((clinic) => ({
                label: clinic.name,
                value: String(clinic.id),
                selected: clinic.id === newRfProfile?.card?.hospital?.id,
              }))}
              onSelect={onClinicSelect}
            />
          </StyledInputContainer2> */}
                            </StyledRowContainer2>
                        ))}
                    </StyledAmbulatoryCardsContainer>
                )}
            </StyledAmbulatoryCardContainer>

            {!editedPatient?.confirmed && (
                <StyledRowContainer>
                    <Button
                        type="PRIMARY"
                        text="Подтвердить"
                        onClick={() => {
                            onPatientConfirm();
                        }}
                    />
                </StyledRowContainer>
            )}
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
  /* margin-bottom: 32px; */
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 16px;
`;

const StyledInputContainer = styled.div`
  width: 100%;
`;

const StyledInputContainer2 = styled.div`
  width: 50%;
`;

const StyledFoundPatientListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

// const StyledFoundPatientContainer = styled.div``;

const StyledAmbulatoryCardContainer = styled.div``;

const StyledRowContainer2 = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-end;

  gap: 16px;
`;

const StyledSelectButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const StyledAmbulatoryCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
