import styled from "styled-components";
import { Title } from "../../../../componentsNew/Title";
import { Radio } from "../../../../componentsNew/Radio";
import { useDispatch, useSelector } from "react-redux";
import {
  researchTypeSelector,
  setResearchType,
} from "../../redux/researchType";

export const ResearchType = () => {
  const dispatch = useDispatch();

  const researchType = useSelector(researchTypeSelector);

  const onResearchTypeSelect = (selectedResearchType: any) => {
    dispatch(setResearchType(selectedResearchType));
  };

  const researchTypes = [
    {
      code: "MOLECULAR",
      name: "Молекулярно-генетическое исследование",
      selected: researchType.code === "MOLECULAR",
    },
    {
      code: "IGH",
      name: "Иммуногистохимическое исследование",
      selected: researchType.code === "IGH",
    },
    { code: "NGS", name: "NGS", selected: researchType.code === "NGS" },
  ];

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Тип направления</Title>
      </StyledTitleContainer>

      <StyledListContainer>
        {/* <Radio text="Молекулярно-генетическое исследование" />
        <Radio selected={true} text="Иммуногистохимическое исследование" />
        <Radio text="NGS" /> */}
        {researchTypes.map((researchType) => (
          <Radio
            key={researchType.code}
            selected={researchType.selected}
            text={researchType.name}
            onSelect={() => onResearchTypeSelect(researchType)}
          />
        ))}
      </StyledListContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */

  /* padding: 32px 24px; */
`;

const StyledTitleContainer = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
