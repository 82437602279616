import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../../store";
import { Patient } from "../../../store/patient/model";
import { ApiPatient } from "../../../services/Patients";

export type PatientState = {
  patients: Array<ApiPatient>;
  selectedPatientUlid: string | null;
  selectedPatientConfirmed: boolean;

  citizenshipCode: string | null;

  rfSearchFirstName: string | null;
  rfSearchLastName: string | null;
  rfSearchPatronymic: string | null;
  rfSearchBirthdate: string | null;

  searchProcessLoading: boolean;
  searchProcessInitiated: boolean;
  searchProcessResult: any | null;

  foreignSearchPassportOrId: string | null;

  editedPatient: {
    ulid: string | null;
    idPassport: string | null;
    address: string | null;
    countryId: number;
    countryName: string;
    countryAlphaCode: string;
    gender: "Male" | "Female" | null;
    lastName: string | null;
    firstName: string | null;
    patronymic: string | null;
    oms: string | null;
    snils: string | null;
    birthdate: string | null;
    fullName: string | null;

    ambulatoryCard: {
      number: string | null;
      organizationId: number | null;
      organizationName: string | null;
    };

    confirmed: boolean;
  } | null;

  newRfProfile: {
    citizenship: string | null;
    passport: string | null;
    address: string | null;
    oms: string | null;
    snils: string | null;
    firstName: string | null;
    lastName: string | null;
    patronymic: string | null;
    birthdate: string | null;
    sexCode: "M" | "F" | null;

    card: {
      number: string | null;
      hospital: any | null;
    };
  };

  foundRfProfile: {
    citizenship: string | null;
    passport: string | null;
    address: string | null;
    oms: string | null;
    snils: string | null;

    fullName: string | null;

    sex: string | null;
    birthdate: string | null;

    card: {
      number: string | null;
      hospital: any | null;
    };

    newCard: {
      number: string | null;
      hospital: any | null;
    };

    otherHospital: string;
    otherReferrals: Array<any>;
  } | null;

  newForeignProfile: {
    citizenship: string | null;

    passportOrId: string | null;
    address: string | null;

    firstName: string | null;
    lastName: string | null;
    patronymic: string | null;
    birthdate: string | null;

    sexCode: "M" | "F" | null;

    outpatientCard: {
      number: string | null;
      hospital: any | null;
    };
  } | null;

  foundForeignProfiles: Array<{
    citizenship: string | null;

    passportOrId: string | null;
    address: string | null;

    fullName: string | null;

    sex: string | null;

    birthdate: string | null;

    card: {
      number: string | null;
      hospital: any | null;
    };

    newCard: {
      number: string | null;
      hospital: any | null;
    };
  }>;
};

const initialState: PatientState = {
  patients: [],
  selectedPatientUlid: null,
  selectedPatientConfirmed: false,

  citizenshipCode: null,

  rfSearchFirstName: null,
  rfSearchLastName: null,
  rfSearchPatronymic: null,
  rfSearchBirthdate: null,

  foreignSearchPassportOrId: null,

  searchProcessLoading: false,
  searchProcessInitiated: false,
  searchProcessResult: null,

  // newProfile: {
  //   citizenship: null;
  // }

  editedPatient: null,

  newRfProfile: {
    citizenship: null,
    passport: null,
    address: null,
    oms: null,
    snils: null,
    firstName: null,
    lastName: null,
    patronymic: null,
    birthdate: null,
    sexCode: null,
    card: {
      number: null,
      hospital: null,
    },
  },

  foundRfProfile: null,
  newForeignProfile: null,
  foundForeignProfiles: [],
};

export const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    setPatients: (state, action: PayloadAction<Array<ApiPatient>>) => {
      state.patients = action.payload;
    },

    setSelectedPatientUlid: (state, action: PayloadAction<string>) => {
      state.selectedPatientUlid = action.payload;
    },

    setSelectedPatientConfirmed: (state, action: PayloadAction<boolean>) => {
      state.selectedPatientConfirmed = action.payload;
    },

    setEditedPatient: (state, action: PayloadAction<any>) => {
      state.editedPatient = action.payload;
    },

    setCitizenshipCode: (
      state,
      action: PayloadAction<{ citizenshipCode: string }>
    ) => {
      state.citizenshipCode = action.payload.citizenshipCode;
    },

    setRfSearchFirstName: (state, action: PayloadAction<string>) => {
      state.rfSearchFirstName = action.payload;
    },

    setRfSearchLastName: (state, action: PayloadAction<string>) => {
      state.rfSearchLastName = action.payload;
    },

    setRfSearchPatronymic: (state, action: PayloadAction<string>) => {
      state.rfSearchPatronymic = action.payload;
    },

    setRfSearchBirthdate: (state, action: PayloadAction<string>) => {
      state.rfSearchBirthdate = action.payload;
    },

    setForeignSearchPassportOrId: (state, action: PayloadAction<string>) => {
      state.foreignSearchPassportOrId = action.payload;
    },

    resetSearch: (state) => {
      state.searchProcessLoading = false;
      state.searchProcessInitiated = false;
      state.searchProcessResult = null;

      state.rfSearchFirstName = null;
      state.rfSearchLastName = null;
      state.rfSearchPatronymic = null;
      state.rfSearchBirthdate = null;

      state.foreignSearchPassportOrId = null;
    },

    setSearchProcessLoading: (state, action: PayloadAction<boolean>) => {
      state.searchProcessLoading = action.payload;
    },

    setSearchProcessInitiated: (state, action: PayloadAction<boolean>) => {
      state.searchProcessInitiated = action.payload;
    },

    setSearchProcessResult: (
      state,
      action: PayloadAction<Array<Patient> | null>
    ) => {
      state.searchProcessResult = action.payload;
    },

    // setNewProfile: () => {},

    // setFoundProfile: () => {},

    // setNewRfProfile: (state, action: PayloadAction<any>) => {
    //   state.newRfProfile = action.payload;
    // },

    resetNewRfProfile: (state) => {
      state.newRfProfile.citizenship = null;
      state.newRfProfile.passport = null;
      state.newRfProfile.address = null;
      state.newRfProfile.oms = null;
      state.newRfProfile.snils = null;
      state.newRfProfile.firstName = null;
      state.newRfProfile.lastName = null;
      state.newRfProfile.patronymic = null;
      state.newRfProfile.birthdate = null;
      state.newRfProfile.sexCode = null;
      state.newRfProfile.card.number = null;
      state.newRfProfile.card.hospital = null;
    },

    setNewRfProfileCitizenship: (state, action: PayloadAction<string>) => {
      console.log("setNewRfProfileCitizenship", state.newRfProfile, action);
      state.newRfProfile.citizenship = action.payload;
    },

    setNewRfProfilePassport: (state, action: PayloadAction<string>) => {
      state.newRfProfile.passport = action.payload;
    },

    setNewRfProfileAddress: (state, action: PayloadAction<string>) => {
      state.newRfProfile.address = action.payload;
    },

    setNewRfProfileOms: (state, action: PayloadAction<string>) => {
      state.newRfProfile.oms = action.payload;
    },

    setNewRfProfileSnils: (state, action: PayloadAction<string>) => {
      state.newRfProfile.snils = action.payload;
    },

    setNewRfProfileFirstName: (state, action: PayloadAction<string>) => {
      state.newRfProfile.firstName = action.payload;
    },

    setNewRfProfileLastName: (state, action: PayloadAction<string>) => {
      state.newRfProfile.lastName = action.payload;
    },

    setNewRfProfilePatronymic: (state, action: PayloadAction<string>) => {
      state.newRfProfile.patronymic = action.payload;
    },

    setNewRfProfileBirthdate: (state, action: PayloadAction<string>) => {
      state.newRfProfile.birthdate = action.payload;
    },

    setNewRfProfileSexCode: (state, action: PayloadAction<"M" | "F">) => {
      state.newRfProfile.sexCode = action.payload;
    },

    setNewRfProfileCardNumber: (state, action: PayloadAction<string>) => {
      state.newRfProfile.card.number = action.payload;
    },

    setNewRfProfileCardHospital: (state, action: PayloadAction<any>) => {
      state.newRfProfile.card.hospital = action.payload;
    },
  },
});

export const {
  setPatients,
  setSelectedPatientUlid,
  setSelectedPatientConfirmed,
  setEditedPatient,

  setCitizenshipCode,

  setRfSearchFirstName,
  setRfSearchLastName,
  setRfSearchPatronymic,
  setRfSearchBirthdate,

  setForeignSearchPassportOrId,

  resetSearch,
  setSearchProcessLoading,
  setSearchProcessInitiated,
  setSearchProcessResult,

  resetNewRfProfile,
  setNewRfProfileCitizenship,
  setNewRfProfilePassport,
  setNewRfProfileAddress,
  setNewRfProfileOms,
  setNewRfProfileSnils,
  setNewRfProfileFirstName,
  setNewRfProfileLastName,
  setNewRfProfilePatronymic,
  setNewRfProfileBirthdate,
  setNewRfProfileSexCode,
  setNewRfProfileCardNumber,
  setNewRfProfileCardHospital,
} = patientSlice.actions;

export const patientReducer = patientSlice.reducer;

const getState = (state: IAppState) => state.createReferralPageNew;

export const patientsSelector = createSelector(
  getState,
  (state) => state.patient.patients
);

export const selectedPatientUlidSelector = createSelector(
  getState,
  (state) => state.patient.selectedPatientUlid
);

export const selectedPatientConfirmedSelector = createSelector(
  getState,
  (state) => state.patient.selectedPatientConfirmed
);

export const editedPatientSelector = createSelector(
  getState,
  (state) => state.patient.editedPatient
);

export const rfSearchFirstNameSelector = createSelector(
  getState,
  (state) => state.patient.rfSearchFirstName
);

export const rfSearchLastNameSelector = createSelector(
  getState,
  (state) => state.patient.rfSearchLastName
);

export const rfSearchPatronymicSelector = createSelector(
  getState,
  (state) => state.patient.rfSearchPatronymic
);

export const rfSearchBirthdateSelector = createSelector(
  getState,
  (state) => state.patient.rfSearchBirthdate
);

export const foreignSearchPassportOrIdSelector = createSelector(
  getState,
  (state) => state.patient.foreignSearchPassportOrId
);

export const patientCitizenshipCodeSelector = createSelector(
  getState,
  (state) => state.patient.citizenshipCode
);

export const searchProcessLoadingSelector = createSelector(
  getState,
  (state) => state.patient.searchProcessLoading
);

export const searchProcessInitiatedSelector = createSelector(
  getState,
  (state) => state.patient.searchProcessInitiated
);

export const searchProcessResultSelector = createSelector(
  getState,
  (state) => state.patient.searchProcessResult
);

export const newRfProfileSelector = createSelector(
  getState,
  (state) => state.patient.newRfProfile
);
