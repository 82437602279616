import styled from "styled-components";

import { Textarea } from "../../../../../../componentsNew/Textarea";
import { useDispatch, useSelector } from "react-redux";
import {
  additionalClinicalDataSelector,
  setAdditionalClinicalData,
} from "../../../../redux/clinicalData";

export const AdditionalClinicalData = () => {
  const dispatch = useDispatch();

  const additionalClinicalData = useSelector(additionalClinicalDataSelector);

  return (
    <StyledContainer>
      <Textarea
        title="Дополнительные клинические сведения"
        required={true}
        placeholder="Свободный ввод"
        value={additionalClinicalData || ""}
        onChange={(event) => {
          dispatch(setAdditionalClinicalData(event.target.value));
        }}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div``;
