import styled from "styled-components";
import { StepsType } from "../../types/StepsType";
import { Colors } from "../../../../styles/themeNew";

import { StepState, Step as StepType } from "../../types/Step";

type Props = {
  type: StepsType;
  step: StepType;
  index: number;

  onChange: () => void;
};

export const Step = (props: Props) => {
  return (
    <StyledContainer onClick={() => props.onChange()} key={props.step.code}>
      {props.step.state === StepState.WITH_SUCCESS && (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="1" y="1" width="28" height="28" rx="14" fill="#0ED962" />
          <rect
            x="1"
            y="1"
            width="28"
            height="28"
            rx="14"
            stroke="#0ED962"
            strokeWidth="2"
          />
          <path
            d="M10 14.6923C10 14.6923 13.5456 19 13.9473 19C14.3489 19 20 11 20 11"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      )}

      {props.step.state !== StepState.WITH_SUCCESS && (
        <StyledCircleContainer stepState={props.step.state}>
          {props.index}
        </StyledCircleContainer>
      )}

      {/* <StyledTextContainer>{props.step.name}</StyledTextContainer> */}
    </StyledContainer>
  );
};

export const StyledContainer = styled.div`
  /* display: flex;
  flex-direction: column;


  gap: 20px; */
  position: relative;

  &:after {
    /* content: " 🦄"; */
    /* content: "";
    width: 100%;
    height: 4px;
    background: red; */

    /* content:" ";
  height: 2px;
  width: 100%;
  background: red;
  display: block;
  position: relative;
  top: 50%;
  right: -100%; */
  }
`;

const getBackgroundColor = (stepState: StepState) => {
  if (stepState === StepState.SELECTED) {
    return "transparent";
  }

  if (stepState === StepState.WITH_SUCCESS) {
    return Colors.GREEN[500];
  }

  if (stepState === StepState.WITH_WARNING) {
    return Colors.ORANGE[400];
  }

  return "transparent";
};

const getBorderColor = (stepState: StepState) => {
  if (stepState === StepState.SELECTED) {
    return Colors.GREEN[500];
  }

  if (stepState === StepState.DISABLED) {
    return Colors.MONOCHROM[200];
  }

  if (stepState === StepState.WITH_WARNING) {
    return Colors.ORANGE[400];
  }

  return Colors.MONOCHROM[200];
};

const getTextColor = (stepState: StepState) => {
  if (stepState === StepState.SELECTED) {
    return Colors.GREEN[500];
  }

  if (stepState === StepState.DISABLED) {
    return Colors.MONOCHROM[400];
  }

  if (stepState === StepState.WITH_WARNING) {
    return "white";
  }

  return Colors.MONOCHROM[400];
};

const StyledCircleContainer = styled.div<{ stepState: StepState }>`
  /* width: 30%;
    height: 75%;
    padding: 0.5em;
    background-color: #f8d05d;
    margin: 0 auto;
    position: relative; */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;

  border-radius: 50%;
  border: 2px solid ${(props) => getBorderColor(props.stepState)};
  background: ${(props) => getBackgroundColor(props.stepState)};

  color: ${(props) => getTextColor(props.stepState)};
  font-size: 14px;
  font-weight: 600;

  cursor: pointer;
`;

// background: ${props => props.primary ? "palevioletred" : "white"};
// color: ${props => props.primary ? "white" : "palevioletred"};

// const StyledTextContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   position: absolute;
//   width: 100px;
//   left: -100%;

//   color: ${Colors.MONOCHROM[950]};
//   text-align: center;

//   font-size: 10px;
//   font-weight: 500;
// `;
