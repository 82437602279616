import { PropsWithChildren } from "react";
import styled from "styled-components";
import { Colors } from "../../styles/themeNew";

type Props = {
  level: string;
};

export const Title = (props: PropsWithChildren<Props>) => {
  return (
    <StyledContainer level={props.level}>{props.children}</StyledContainer>
  );
};

const getFontSize = (level: string) => {
  if (level === "1") {
    return "24px";
  }

  if (level === "2") {
    return "20px";
  }

  if (level === "3") {
    return "18px";
  }

  return "16px";
};

const StyledContainer = styled.div<{ level: string }>`
  font-size: ${(props) => getFontSize(props.level)};
  font-style: normal;
  font-weight: 600;
  color: ${Colors.MONOCHROM[950]};
`;
