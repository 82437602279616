import styled from 'styled-components';
import backgroundImageLeftBlock from '../../../images/login_page/bg.png'

export const TWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;
  position: absolute;
`;

export const TBlockLeftWrapper = styled.div`
  width: 65%;
  height: 100vh;
  background: url(${backgroundImageLeftBlock});
  background-position: left top; /* Adjust position if needed */
  background-repeat: no-repeat;
  background-size:100% 100%;
  position: relative;
`;

export const TBlockRigthWrapper = styled.div`
  width: 50%;
  height: 100vh;
  background-color: white; /* Set background color to white */
  position: relative;
`;

export const TBlockLogoWrapper = styled.div`
  width: 100%;
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TLogoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 592px;
  height: 117px;
  border: 2px solid #EAEAEB;
  box-shadow: 1px 1px 20px rgba(122, 120, 233, 0.2);
`

export const TBlock = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  padding: 92px 180px 0 101px;
  box-sizing: border-box;
  align-items: start;
  background-color: transparent;
`
