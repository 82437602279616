import { AxiosInstance } from "axios";
// import { Patient } from "../store/patient/model";

// [
//   {
//     ulid: "pat_01J8D3EN3JA86TWCGMJWQZRMNY",
//     id_passport: "56672996IBgV4177",
//     address: "",
//     country: 44,
//     country_name: "Багамские Острова",
//     country_alpha: "bs",
//     gender: "Female",
//     last_name: "Пестов",
//     first_name: "Регина",
//     middle_name: "Антонина",
//     oms_number: "6440978640619284",
//     snils_number: "52216542168",
//     date_of_birth: "1941-10-17",
//     date_of_death: null,
//     full_name: "Пестов Регина Антонина",
//     ambulatory_cards: [
//       {
//         organization_id: 3599,
//         custom_organization: "и партнеры",
//         number: "5114499828",
//       },
//     ],
//   },
// ];

export type ApiPatientAmbulatoryCard = {
  organization_id: number; // "organization_id": 3599,
  custom_organization: string; // "custom_organization": "и партнеры",
  number: string; // "number": "5114499828"
};

export type ApiPatient = {
  ulid: string; // "ulid": "pat_01J8D3EN3JA86TWCGMJWQZRMNY",
  id_passport: string; // "id_passport": "56672996IBgV4177",
  address: string; // "address": "",
  country: number; // "country": 44,
  country_name: string; // "country_name": "Багамские Острова",
  country_alpha: string; // "country_alpha": "bs",
  gender: string; // "gender": "Female",

  last_name: string; // "last_name": "Пестов",
  first_name: string; // "first_name": "Регина",
  middle_name: string; // "middle_name": "Антонина",

  oms_number: string; // "oms_number": "6440978640619284",
  snils_number: string; // "snils_number": "52216542168",

  date_of_birth: string; // "date_of_birth": "1941-10-17",

  date_of_death: null; // "date_of_death": null,

  full_name: string; // "full_name": "Пестов Регина Антонина",

  ambulatory_cards: Array<ApiPatientAmbulatoryCard>;
};

export class PatientsService {
  private httpClient: AxiosInstance | null = null;

  constructor(httpClient: any) {
    this.httpClient = httpClient;
  }

  async searchPatients(props: {
    lastName?: string;
    firstName?: string;
    middleName?: string;
    dateOfBirth?: string;
    excludeCountryAlpha?: string;
    countryAlpha?: string;
  }): Promise<Array<ApiPatient>> {
    // const url = `registry/patient/list/?search=${id ?? ""}${
    //   !!countryIsBLR ? "&country__blr=true" : ""
    // }`;

    const urlSearchParams = new URLSearchParams("");

    if (props.lastName) {
      urlSearchParams.append("last_name", props.lastName);
    }

    if (props.firstName) {
      urlSearchParams.append("first_name", props.firstName);
    }

    if (props.middleName) {
      urlSearchParams.append("middle_name", props.middleName);
    }

    if (props.dateOfBirth) {
      urlSearchParams.append("date_of_birth", props.dateOfBirth);
    }

    if (props.excludeCountryAlpha) {
      urlSearchParams.append(
        "exclude_country__alpha",
        props.excludeCountryAlpha
      );
    }
    if (props.countryAlpha) {
      urlSearchParams.append("country__alpha", props.countryAlpha);
    }

    const url = `/registry/patient/search?${urlSearchParams.toString()}`;

    return (await this.httpClient?.get(url))?.data;
  }
}
