import { AxiosInstance } from "axios";

export type ApiClinic = {};

export class ClinicsService {
  httpClient: AxiosInstance | null = null;

  constructor(httpClient: any) {
    this.httpClient = httpClient;
  }

  async searchClinics(): Promise<Array<ApiClinic>> {
    const url = `account/organization/list/?type=CLYNICS`;

    return await (
      await this.httpClient?.get(url)
    )?.data;
  }
}
