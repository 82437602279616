// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useSelector } from "react-redux";
import { laboratoriesSelector } from "../redux/laboratory";
// import { LaboratoriesService } from "../../../services/Laboratories";
// import { useHttpClient } from "../../../hooks/useHttpClient";

export const useLaboratories = () => {
  // const dispatch = useDispatch();

  // const httpClient = useHttpClient();

  const laboratories = useSelector(laboratoriesSelector);

  // const effect = async () => {
  //   const laboratoriesService = new LaboratoriesService(httpClient);

  //   const laboratories = await laboratoriesService.searchLaboratories();

  //   if (laboratories?.length) {
  //     dispatch(setLaboratories(laboratories));
  //   }
  // };

  // useEffect(() => {
  //   effect();
  // }, []);

  return { laboratories };
};
