import styled from "styled-components";
import { Title } from "../../../../../../componentsNew/Title";
import { Input } from "../../../../../../componentsNew/Input";
import { Datepicker } from "../../../../../../componentsNew/Datepicker";

export const DoctorNotFound = () => {
  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Специалист не найден. Создайте профиль</Title>
      </StyledTitleContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="УЗ"
            required={true}
            placeholder=""
            value={""}
            onChange={(event) => {
              //   dispatch(setNewRfProfileAddress(event.target.value));
            }}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Фамилия"
            required={true}
            placeholder=""
            value={""}
            onChange={(event) => {
              //   dispatch(setNewRfProfileAddress(event.target.value));
            }}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Имя"
            required={true}
            placeholder=""
            value={""}
            onChange={(event) => {
              //   dispatch(setNewRfProfileAddress(event.target.value));
            }}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Отчество"
            required={true}
            placeholder=""
            value={""}
            onChange={(event) => {
              //   dispatch(setNewRfProfileAddress(event.target.value));
            }}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <Title level="3">Дата направления на исследование</Title>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Datepicker
            required={true}
            title="Дата"
            placeholder=""
            value={""}
            onChange={(event) => {
              // dispatch(setNewRfProfileBirthdate(event.target.value));
            }}
          />
        </StyledInputContainer>
      </StyledRowContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledInputContainer = styled.div`
  width: 100%;
`;
