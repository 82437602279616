import styled from "styled-components";
import { Colors } from "../../styles/themeNew";

type Props = {
  title?: string;
  required?: boolean;
  placeholder?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

  disabled?: boolean;
};

export const Datepicker = (props: Props) => {
  return (
    <StyledContainer>
      {props.title && (
        <StyledLabel htmlFor="datepicker">
          {props.title}{" "}
          {props.required && (
            <StyledAsteriskContainer>*</StyledAsteriskContainer>
          )}
        </StyledLabel>
      )}

      <StyledMainContainer>
        <StyledInput
          type="date"
          id="datepicker"
          name="trip-start"
          placeholder="12.06.1989"
          // value="2018-07-22"
          // min="2018-01-01"
          // max="2018-12-31"
          value={props.value || ""}
          onChange={props.onChange}
          disabled={props.disabled}
        />
      </StyledMainContainer>
    </StyledContainer>
  );
};

const StyledMainContainer = styled.div`
  width: 100%;
  height: 60px;
  box-sizing: border-box;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${Colors.MONOCHROM[950]};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;

  gap: 4px;
`;

const getBackgroundColor = (disabled?: boolean) => {
  if (disabled) {
    return Colors.MONOCHROM[100];
  }

  return "transparent";
};

const StyledInput = styled.input<{
  disabled?: boolean;
}>`
  height: 100%;
  margin: 0;
  padding: 0;

  padding: 8px 16px;

  height: 60px;

  border-radius: 6px;
  border: 1px solid ${Colors.MONOCHROM[200]};
  background: ${(props) => getBackgroundColor(props.disabled)};

  box-sizing: border-box;

  width: 100%;

  font-size: 16px;
  font-weight: 400;

  color: ${Colors.MONOCHROM[950]};

  &::placeholder {
    color: ${Colors.MONOCHROM[400]};
  }
`;

const StyledAsteriskContainer = styled.div`
  color: ${Colors.RED[500]};
  font-size: 12px;
  font-weight: 400;
`;
