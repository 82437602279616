import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../../store";

export type BiomaterialRegistrationState = {
  material: string | null;
  materialType: string | null;
  obtaining: string | null;
  sampleType: string | null;
  date: string | null;
};

const initialState: BiomaterialRegistrationState = {
  material: null,
  materialType: null,
  obtaining: null,
  sampleType: null,
  date: null,
};

export const biomaterialRegistrationSlice = createSlice({
  name: "biomaterialRegistration",
  initialState,
  reducers: {
    setMaterial: (state, action: PayloadAction<string>) => {
      state.material = action.payload;
    },
    setMaterialType: (state, action: PayloadAction<string>) => {
      state.materialType = action.payload;
    },
    setObtaining: (state, action: PayloadAction<string>) => {
      state.obtaining = action.payload;
    },
    setSampleType: (state, action: PayloadAction<string>) => {
      state.sampleType = action.payload;
    },
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
  },
});

export const {
  setMaterial,
  setMaterialType,
  setObtaining,
  setSampleType,
  setDate,
} = biomaterialRegistrationSlice.actions;

export const biomaterialRegistrationReducer =
  biomaterialRegistrationSlice.reducer;

const getState = (state: IAppState) => state.createReferralPageNew;

export const materialSelector = createSelector(
  getState,
  (state) => state.biomaterialRegistration.material
);

export const materialTypeSelector = createSelector(
  getState,
  (state) => state.biomaterialRegistration.materialType
);

export const obtainingSelector = createSelector(
  getState,
  (state) => state.biomaterialRegistration.obtaining
);

export const sampleTypeSelector = createSelector(
  getState,
  (state) => state.biomaterialRegistration.sampleType
);

export const dateSelector = createSelector(
  getState,
  (state) => state.biomaterialRegistration.date
);
