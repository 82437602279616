import { AxiosInstance } from "axios";

export type ApiLocalization = {
  //   id: 2;
  //   type_eng: "Колоректальный рак";
  //   type_rus: "Колоректальный рак";
  //   description: "";

  id: number;
  type_eng: string;
  type_rus: string;
  description: string;
};

export class LocalizationsService {
  private httpClient: AxiosInstance | null = null;

  constructor(httpClient: any) {
    this.httpClient = httpClient;
  }

  async searchLocalizations(): Promise<Array<ApiLocalization> | undefined> {
    const url = `registry/localization/list`;

    return await (
      await this.httpClient?.get<Array<ApiLocalization>>(url)
    )?.data;
  }
}
