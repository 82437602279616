import styled from "styled-components";

import { Title } from "../../../../componentsNew/Title";
import { TnmClassification } from "./components/TnmClassification";
import { ClinicalDiagnosis } from "./components/ClinicalDiagnosis";
import { Localization } from "./components/Localization";
import { MkbDiagnosis } from "./components/MkbDiagnosis";
import { Stage } from "./components/Stage";
import { AdditionalClinicalData } from "./components/AdditionalClinicalData";
import { Results } from "./components/Results";
import { Treatment } from "./components/Treatment";

export const ClinicalData = () => {
  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Клинические данные</Title>
      </StyledTitleContainer>

      <Localization />

      <MkbDiagnosis />

      <ClinicalDiagnosis />

      <TnmClassification />

      <Stage />

      <AdditionalClinicalData />

      <Results />

      <Treatment />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;

  padding-bottom: 200px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
`;
