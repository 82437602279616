import { combineReducers } from "@reduxjs/toolkit";

import { researchTypeReducer } from "./researchType";
import { currentStepReducer } from "./currentStep";
import { laboratoryReducer } from "./laboratory";
import { patientReducer } from "./patient";
import { referrerReducer } from "./referrer";
import { clinicalDataReducer } from "./clinicalData";
import { biomaterialRegistrationReducer } from "./biomaterialRegistration";
import { markersReducer } from "./markers";
import { commonReducer } from "./common";

export const createReferralPageNewReducer = combineReducers({
  researchType: researchTypeReducer,
  currentStep: currentStepReducer,
  laboratory: laboratoryReducer,
  patient: patientReducer,
  referrer: referrerReducer,
  clinicalData: clinicalDataReducer,
  biomaterialRegistration: biomaterialRegistrationReducer,
  markers: markersReducer,
  common: commonReducer,
});
