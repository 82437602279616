import styled, { css } from 'styled-components';
import { SIZE, VARIANT } from './const';

interface ITButton {
  size?: SIZE;
  variant?: VARIANT;
}

const SIZES = {
  [SIZE.SMALL]: css`
    width: max-content;
    min-width: 200px;
  `,
  [SIZE.MEDIUM]: css`
    width: 100%
  `,
  [SIZE.LARGE]: css`
    width: 416px;
  `,
};

const VARIANTS = {
  [VARIANT.DEFAULT]: css`
    color: ${({ theme }) => theme.colors.white};
    background-color: #3e6aff;

    &:disabled {
      cursor: default;
      background-color: ${({ theme }) => theme.colors.neutral.disable};
    }

    &:hover:enabled {
      background-color: #345be0; // Manually specify a slightly darker color
    }
  `,
  [VARIANT.TRANSPARENT]: css`
    color: ${({ theme }) => theme.colors.product.primaryActive};
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.colors.product.primaryActive};

    &:disabled {
      cursor: default;
      border-color: ${({ theme }) => theme.colors.neutral.disable};
      color: ${({ theme }) => theme.colors.neutral.disable};
    }

    &:hover:enabled {
      color: ${({ theme }) => theme.colors.product.primaryHover};
      background-color: transparent;
      border: 2px solid ${({ theme }) => theme.colors.product.primaryHover};
    }
  `,
  [VARIANT.LINK]: css`
    color: ${({ theme }) => theme.colors.product.primaryActive};
    background-color: transparent;
    padding: 0;
    width: max-content;
    display: inline;
    font-size: inherit;
    font-weight: 500;

    &:disabled {
      cursor: default;
      color: ${({ theme }) => theme.colors.neutral.disable};
    }

    &:hover:enabled {
      color: ${({ theme }) => theme.colors.product.primaryHover};
      background-color: transparent;
    }
  `,
  [VARIANT.BLUE]: css`
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.blue[200]};
    border: 2px solid ${({ theme }) => theme.colors.blue[200]};

    &:disabled {
      cursor: default;
      background-color: transparent;
      border: 2px solid ${({ theme }) => theme.colors.neutral.disable};
      color: ${({ theme }) => theme.colors.neutral.disable};
    }

    &:hover:enabled {
      background-color: ${({ theme }) => theme.colors.blue[300]};
      border: 2px solid ${({ theme }) => theme.colors.blue[300]};
      color: ${({ theme }) => theme.colors.white};
    }
  `,
};

const TButton = styled.button<ITButton>`
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 50px;
  border-style: none;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  font: inherit;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  height: 52px;
  ${({ size = SIZE.MEDIUM }) => SIZES[size]};
  ${({ variant = VARIANT.DEFAULT }) => VARIANTS[variant]};
`;

const TButtonText = styled.span`
  display: block;
`;

export { TButton, TButtonText };
