import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { Input } from "../../../../../../componentsNew/Input";

import {
  setStage,
  setSub,
  stageSelector,
  subSelector,
} from "../../../../redux/clinicalData";

export const Stage = () => {
  const dispatch = useDispatch();

  const stage = useSelector(stageSelector);
  const sub = useSelector(subSelector);

  return (
    <StyledContainer>
      <StyledInputContainer>
        <Input
          title="Стадия"
          required={true}
          placeholder="Стадия"
          value={stage || ""}
          onChange={(event) => {
            dispatch(setStage(event.target.value));
          }}
        />
      </StyledInputContainer>

      <StyledInputContainer>
        <Input
          placeholder="Sub"
          value={sub || ""}
          onChange={(event) => {
            dispatch(setSub(event.target.value));
          }}
        />
      </StyledInputContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;
`;

const StyledInputContainer = styled.div`
  width: 50%;
`;
