import styled from "styled-components";
import { StepState, Step as StepType } from "./types/Step";
import { Step } from "./components/Step";
import { StepsType } from "./types/StepsType";
import { Colors } from "../../styles/themeNew";
import React from "react";

type Props = {
  type: StepsType;
  steps: Array<StepType>;

  onChange: (stepCode: string) => void;
};

export const Steps = (props: Props) => {
  return (
    <StyledContainer>
      <StyledTopContainer>
        {props.steps.map((step, index) => (
          <React.Fragment key={step.code}>
            <Step
              type={props.type}
              step={step}
              index={index + 1}
              onChange={() => props.onChange(step.code)}
            />
            {index !== props.steps.length - 1 && <StyledLineContainer />}
          </React.Fragment>
        ))}
      </StyledTopContainer>

      <StyledBottomContainer>
        {props.steps.map((step, index) => (
          <StyledTextContainer
            key={step.code}
            stepState={step.state}
            onClick={() => props.onChange(step.code)}
          >
            {step.name}
          </StyledTextContainer>
        ))}
      </StyledBottomContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 10px;

  background-color: white;
`;

const StyledTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* padding: 0 20px; */
`;

const StyledLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 2px;
  background-color: ${Colors.MONOCHROM[200]};
`;

// background: ${props => props.primary ? "palevioletred" : "white"};
// color: ${props => props.primary ? "white" : "palevioletred"};

const StyledBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 4px;
`;

const getTextColor = (stepState: StepState) => {
  if (stepState === StepState.DISABLED) {
    return Colors.MONOCHROM[400];
  }

  return Colors.MONOCHROM[950];
};

const StyledTextContainer = styled.div<{ stepState: StepState }>`
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  width: 100px;
  left: -100%; */

  display: flex;
  flex-direction: row;

  justify-content: center;

  color: ${Colors.MONOCHROM[950]};
  text-align: center;

  font-size: 10px;
  font-weight: 500;

  width: 30px;

  color: ${(props) => getTextColor(props.stepState)};

  cursor: pointer;
`;
