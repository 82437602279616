import styled from "styled-components";
import { Title } from "../../../../componentsNew/Title";
import { Select } from "../../../../componentsNew/Select";
import { laboratorySelector, setLaboratory } from "../../redux/laboratory";
import { useDispatch, useSelector } from "react-redux";
import { useLaboratories } from "../../hooks/useLaboratories";

export const Laboratory = () => {
  const dispatch = useDispatch();

  const selectedLaboratory = useSelector(laboratorySelector);

  const { laboratories } = useLaboratories();

  const onLaboratorySelect = (option: { label: string; value: string }) => {
    dispatch(
      setLaboratory({
        id: parseInt(option.value, 10),
        name: option.label,
      })
    );
  };

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Выбор лаборатории</Title>
      </StyledTitleContainer>

      <StyledSelectContainer>
        <Select
          placeholder="Выберите лабораторию"
          options={laboratories.map((laboratory) => ({
            label: laboratory.name_official_rus,
            value: String(laboratory.id),
            selected: laboratory.id === selectedLaboratory?.id,
          }))}
          onSelect={onLaboratorySelect}
        />
      </StyledSelectContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding-bottom: 200px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const StyledSelectContainer = styled.div``;
